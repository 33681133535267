import React, { useRef, useState } from "react";
import SignIn from "./SignIn";
import chime from "./chime.mp3";
import Avatar from "./Avatar";
import SetNicknameDialog from "./SetNicknameDialog";
import { UserContext } from "./UserContext";

// https://freefrontend.com/tailwind-chats/

class WebSocketConnector {
  private static readonly SOCKET_URL = "wss://chat-socket.henarv.se";
  private connection?: WebSocket;
  private pingTimeout?: NodeJS.Timer;

  getConnection(
    authToken: string,
    onOpen: (this: WebSocket, ev: Event) => any,
    onMessage: (this: WebSocket, ev: MessageEvent) => any,
    onClose: (this: WebSocket, ev: CloseEvent) => any
  ) {
    if (
      !this.connection /*|| this.connection.readyState === WebSocket.CLOSED*/
    ) {
      console.log(`### Socket create connection at ${new Date()}`);

      this.connection = new WebSocket(
        `${WebSocketConnector.SOCKET_URL}?Auth=${authToken}`
      );

      clearTimeout(this.pingTimeout);
      this.pingTimeout = setInterval(() => {
        if (this.connection?.readyState !== WebSocket.OPEN) {
          console.log(`### Socket could not send ping at ${new Date()}`);
          clearTimeout(this.pingTimeout);
          return;
        }

        console.log(`### Socket send ping at ${new Date()}`);
        this.connection?.send(JSON.stringify({ action: "ping" }));
      }, 1000 * 60 * 9);
    }

    this.connection.onopen = onOpen;
    this.connection.onclose = onClose;
    this.connection.onmessage = onMessage;
    this.connection.addEventListener("error", (event) => {
      console.log("WebSocket error: ", event);
    });

    return this.connection;
  }
}

const webSocketConnector = new WebSocketConnector();
console.log("### new WebSocketConnector();");

type WsEvent = {
  eventType: String;
  signIn: {
    nicknameMissing: boolean;
    nickname?: string;
    email: string;
  };
  users: [
    {
      userId: string;
      nickname: string;
      email: string;
      online: boolean;
    }
  ];
  message: {
    conversationId: string;
    sentAt: string;
    fromUserId: string;
    text: string;
  };
  conversation: {
    conversationId: string;
    messages: [
      {
        sentAt: string;
        fromUserId: string;
        text: string;
      }
    ];
  };
};

type Message = {
  text: string;
  fromUserId: string;
};

type User = {
  userId: string;
  nickname: string;
  email: string;
  online: boolean;
};

const audio = new Audio(chime);

function AppChat() {
  const [accessToken] = React.useContext(UserContext).useAccessToken;
  const [userId] = React.useContext(UserContext).useUserId;
  const [nickname, setNickname] = React.useContext(UserContext).useNickname;
  const [nicknameMissing, setNicknameMissing] =
    React.useContext(UserContext).useNicknameMissing;

  const webSocketConnectorRef = useRef(webSocketConnector);

  const [contactList, setContactList] = useState<User[]>([]);
  const [selectedContactUserId, setSelectedContactUserId] = useState("");
  const [unreadMessageMap, setUnreadMessageMap] = useState(
    new Map<string, number>()
  );
  const sendMessageInputRef = useRef<HTMLInputElement>(null);
  const [conversation, setConversation] = useState<Message[]>([]);

  if (userId === "") {
    return <SignIn />;
  }

  console.log("### webSocketConnectorRef.current.getConnection(url)");

  const onWebSocketOpen = () => {
    console.log(`### Socket opened at ${new Date()}`);
    ws.send(JSON.stringify({ action: "signIn" }));
  };

  const onWebsocketClose = (ev: CloseEvent) => {
    console.log(`### Socket closed at ${new Date()}`);
    ws = webSocketConnectorRef.current.getConnection(
      accessToken,
      onWebSocketOpen,
      onWebSocketMessage,
      onWebsocketClose
    );
  };

  const onWebSocketMessage = (ev: MessageEvent) => {
    console.log(`### On Message at ${new Date()} ${ev.data}`);

    const event = JSON.parse(ev.data) as WsEvent;

    if (event.eventType === "SIGN_IN") {
      setNickname(event.signIn.nickname ?? "");
      setNicknameMissing(event.signIn.nicknameMissing);
    }
    if (event.eventType === "USERS") {
      setContactList(event.users);

      if (
        contactList.find((u) => u.userId === selectedContactUserId) ===
        undefined
      ) {
        setSelectedContactUserId("");
      }
    }
    if (event.eventType === "CONVERSATION") {
      setConversation(event.conversation.messages.reverse());
    }
    if (event.eventType === "MESSAGE") {
      if (event.message.fromUserId === userId) {
        return;
      } else if (event.message.fromUserId === selectedContactUserId) {
        setConversation((oldArray) => [event.message, ...oldArray]);
        audio.play();
      } else {
        const unread =
          (unreadMessageMap.get(event.message.fromUserId) || 0) + 1;
        unreadMessageMap.set(event.message.fromUserId, unread);
        setUnreadMessageMap(new Map(unreadMessageMap));
        audio.play();
      }
    }
  };

  let ws = webSocketConnectorRef.current.getConnection(
    accessToken,
    onWebSocketOpen,
    onWebSocketMessage,
    onWebsocketClose
  );

  const handleOnClickContact = (n: string) => {
    setSelectedContactUserId(n);
    unreadMessageMap.delete(n);
    setUnreadMessageMap(new Map(unreadMessageMap));

    ws.send(JSON.stringify({ action: "getConversation", with: n }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const text = sendMessageInputRef.current!.value.trim();
    if (e.key === "Enter" && text.length > 0) {
      ws.send(
        JSON.stringify({
          action: "sendMessage",
          message: text,
          to: selectedContactUserId,
        })
      );
      setConversation((oldArray) => [
        { text: text, fromUserId: userId },
        ...oldArray,
      ]);
      sendMessageInputRef.current!.value = "";
    }
  };

  const renderContacts = () => {
    const selectedStyle = "bg-slate-100";

    return contactList.map((c) => (
      <div
        className={
          "flex flex-row py-2 px-2 justify-center items-center border-2 rounded-full _mb-2 " +
          (c.userId === selectedContactUserId ? selectedStyle : "")
        }
        key={c.userId}
        onClick={() => handleOnClickContact(c.userId)}
      >
        <div className="">
          <div className={"mr-2"}>
            <Avatar userId={c.userId} online={c.online}></Avatar>
          </div>
        </div>
        <div className="flex w-full items-baseline">
          <div className="w-full text-lg font-semibold">{c.nickname}</div>
          <div className="text-right w-12">
            {unreadMessageMap.get(c.userId)
              ? "(" + unreadMessageMap.get(c.userId) + ")"
              : ""}
          </div>
        </div>
      </div>
    ));
  };

  const renderSentMessage = (
    from: string,
    messageText: string,
    index: number
  ) => {
    return (
      <div key={index} className="flex justify-end mr-4">
        <div className="min-w-1/5"></div>
        <div className="mr-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white break-words">
          {messageText}
        </div>

        <Avatar userId={from} online={true} />
      </div>
    );
  };

  const renderReceivedMessage = (
    from: string,
    messageText: string,
    index: number
  ) => {
    const online = contactList.find((c) => c.userId === from)?.online ?? false;

    return (
      <div key={index} className="flex justify-start mr-4">
        <Avatar userId={from} online={online} />
        <div className="ml-2 py-3 px-4 bg-gray-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white break-words">
          {messageText}
        </div>
        <div className="min-w-1/5"></div>
      </div>
    );
  };

  const renderConversation = () => {
    return conversation.map((m, index) => {
      if (m.fromUserId === userId) {
        return renderSentMessage(m.fromUserId, m.text, index);
      } else {
        return renderReceivedMessage(m.fromUserId, m.text, index);
      }
    });
  };

  if (nicknameMissing) {
    return <SetNicknameDialog webSocket={ws} />;
  }
  if (!nickname) {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center1 sm:py-12">
        <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
          <h1 className="font-bold text-center text-2xl mb-5">Loading</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen p-5 shadow-lg rounded-lg _bg-slate-200">
      <div className="pb-5 flex justify-between items-center bg-white border-b-2">
        <div className="font-semibold text-2xl">Serverless Chat</div>
        <div className="flex items-center py-2 px-2 _justify-center _items-center border-2 rounded-full _mb-2">
          <span className="font-semibold text-lg m-2">{nickname}</span>
          <Avatar userId={userId} online={true} />
        </div>
      </div>
      <div className="flex h-full flex-row justify-between bg-white _pt-5 ">
        <div className="flex w-1/3 flex-col border-r-2 pr-5 pt-5">
          <div className="h-0 flex-auto overflow-y-scroll pr-5 space-y-2">
            {renderContacts()}
          </div>
        </div>
        <div className="flex w-2/3 pl-5 flex-col justify-between pt-5">
          {!selectedContactUserId && (
            <div className="-h-full text-center m-auto">
              Select a user to send message.
            </div>
          )}
          {selectedContactUserId && (
            <>
              <div className="flex flex-auto h-0 flex-col-reverse overflow-y-auto space-y-reverse space-y-2">
                {renderConversation()}
              </div>
              <div className="pt-5">
                <input
                  className="w-full bg-gray-300 py-5 px-3 rounded-xl"
                  type="text"
                  placeholder="type your message here..."
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  ref={sendMessageInputRef}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppChat;

/*
<div class="flex h-screen flex-col">
  <div class="bg-yellow-50">Banner</div>
  <div class="flex h-full border-2 bg-red-100">
    <div class="flex w-1/3 flex-col bg-blue-100">
      <div class="h-0 flex-auto overflow-y-auto">
        <p>X</p>
      </div>
    </div>
    <div class="flex w-2/3 flex-col bg-blue-50">
      <div class="h-0 flex-auto overflow-y-auto">
        <p>111</p>
      </div>
      <div>11</div>
    </div>
  </div>
</div>
*/
