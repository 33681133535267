import { toSvg } from "jdenticon";
import React from "react";

const Avatar = ({ userId, online }: { userId: string; online: boolean }) => {
  const avatar = toSvg(userId, 100);
  const color = online ? " bg-green-400" : " bg-gray-300";

  return (
    <div
      className={
        "h-12 w-12 min-w-[3rem] rounded-full flex items-center justify-center" +
        color
      }
    >
      <div
        className="h-10 w-10 _min-w-[3rem] p-1 bg-white rounded-full flex items-center justify-center"
        dangerouslySetInnerHTML={{ __html: avatar }}
      />
    </div>
  );
};

export default Avatar;
