import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  IAuthenticationCallback,
  ICognitoUserPoolData,
} from "amazon-cognito-identity-js";

const poolData: ICognitoUserPoolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
};

export const pool = new CognitoUserPool(poolData);

export const cognitoSignIn = (
  userId: string,
  password: string,
  callbacks: IAuthenticationCallback
) => {
  const cognitoUser = new CognitoUser({
    Username: userId,
    Pool: pool,
  });

  const authDetails = new AuthenticationDetails({
    Username: userId,
    Password: password,
  });

  cognitoUser.authenticateUser(authDetails, callbacks);
};
