import {
  CognitoUserSession,
  IAuthenticationCallback,
} from "amazon-cognito-identity-js";
import React, { useRef, useState } from "react";
import { UserContext } from "./UserContext";
import { cognitoSignIn, pool } from "./UserPool";

const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID!;
const REDIRECT_URL = window.location.origin;

const resetPassword = `https://serverlesschat.auth.us-east-1.amazoncognito.com/forgotPassword?client_id=${COGNITO_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URL}`;
const signUp = `https://serverlesschat.auth.us-east-1.amazoncognito.com/signup?client_id=${COGNITO_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URL}`;

const SignIn = () => {
  const {
    useAccessToken: [, setAccessToken],
    useUserId: [, setUserId],
  } = React.useContext(UserContext);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const onEnter = () => {
    setErrorMessage("");
    const email = emailInputRef.current!.value;
    const password = passwordInputRef.current!.value;

    if (email.length === 0) {
      setErrorMessage("Email cannot be empty.");
      return;
    }

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!emailRegex.test(email)) {
      setErrorMessage("Not an email address.");
      return;
    }

    if (password.length === 0) {
      setErrorMessage("Password cannot be empty.");
      return;
    }

    cognitoSignIn(email, password, {
      onSuccess: (
        session: CognitoUserSession,
        userConfirmationNecessary?: boolean
      ) => {
        console.log("#-- onSuccess ", session, userConfirmationNecessary);
        console.log("#-- 1 ", pool.getCurrentUser());
        console.log("#-- 2 ", session.getIdToken().getJwtToken());
        setUserId(pool.getCurrentUser()!.getUsername());
        setAccessToken(session.getIdToken().getJwtToken());
      },
      onFailure: (err: any) => {
        console.log("#-- onFailure", err, JSON.stringify(err));
        if (err.code === "NotAuthorizedException") {
          setErrorMessage("Incorrect username or password.");
        } else {
          setErrorMessage("Unknown login error.");
        }
      },
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        setErrorMessage("New password required.");
        console.log(
          "#-- newPasswordRequired",
          userAttributes,
          requiredAttributes
        );
      },
    } as IAuthenticationCallback);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center1 sm:py-12">
      <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
        <h1 className="font-bold text-center text-2xl mb-5">
          Serverless Chat Sign In
        </h1>
        <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
          <div className="px-5 py-7">
            <label className="font-semibold text-sm text-gray-600 pb-1 block">
              Email
            </label>
            <input
              type="text"
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              ref={emailInputRef}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <label className="font-semibold text-sm text-gray-600 pb-1 block">
              Password
            </label>
            <input
              type="password"
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              ref={passwordInputRef}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <button
              type="button"
              className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
              onClick={() => onEnter()}
            >
              <span className="inline-block mr-2">Enter</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-4 h-4 inline-block"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </button>
            {errorMessage && (
              <div className="text-center mt-2">
                <span className="text-red-500">{errorMessage}</span>
              </div>
            )}
            <div className="text-center mt-5">
              <a href={signUp} className="underline text-blue-500">
                Sign up
              </a>
              <span> </span>
              <a href={resetPassword} className="underline text-blue-500">
                Reset password
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
