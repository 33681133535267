import React, { createContext, ReactNode, useState } from "react";

type Props = {
  useAccessToken: [string, React.Dispatch<React.SetStateAction<string>>];
  useUserId: [string, React.Dispatch<React.SetStateAction<string>>];
  useNickname: [string, React.Dispatch<React.SetStateAction<string>>];
  useNicknameMissing: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

export const UserContext = createContext({} as Props);

export function UserProvider({ children }: { children: ReactNode }) {
  const [accessToken, setAccessToken] = useState("");
  const [userId, setUserId] = useState("");
  const [nickname, setNickname] = useState("");
  const [nicknameMissing, setNicknameMissing] = useState(false);

  return (
    <UserContext.Provider
      value={{
        useAccessToken: [accessToken, setAccessToken],
        useUserId: [userId, setUserId],
        useNickname: [nickname, setNickname],
        useNicknameMissing: [nicknameMissing, setNicknameMissing],
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
